@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: auto;
}

a {
  text-decoration: none;
}

.section-span {
  color: #030303;
  font-size: 21px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
}

hr {
  color: #030303;
  size: 2;
  width: auto;
}

section {
  padding-top: 75px;
}

.container {
  margin: auto;
}

/*navbar*/
.navbar-brand .logo {
  width: 100px;
}

.navbar-brand .logo-txt {
  background: #4a4c53;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-emphasis-color: transparent;
}

.navbar-brand .logo-txt {
  margin-bottom: 0px;
  font-size: 30px;
  font-weight: 800;
}

.nav-link {
  display: inline-block;
  position: relative;
  padding-bottom: 15px;
}

.nav-link::before {
  transition: 300ms;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #030303;
}

.nav-link::before {
  width: 0%;
  bottom: 10px;
}

.nav-link:hover::before {
  width: 50%;
}

.nav-link.active::before {
  width: 50%;
}

.nav-link.active {
  color: #252425 !important
}

.head-icon {
  color: #323336;
}

.owl-carousel {
  display: block;
}

/**header**/
.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.header-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #252425;
  transition: all ease-in-out 0.5s;
  z-index: 10000;
  animation: stickyheader 1s;
}

@keyframes stickyheader {
  from {
    top: -60px;
  }

  to {
    top: 0;
  }
}

.top-header {
  background-color: #F4F7FF;
  z-index: 1000;
}

.top-header .navbar-nav li.nav-item a.nav-link {
  color: #252425;
  padding: 12px 20px;
  margin: 0px 0px;
  font-weight: 600;
  font-size: 17px;
}

.top-header .navbar-nav li.nav-item a.nav-link:hover {
  color: #747680;
}

/* common css */
.main-title {
  color: #f5f5f8;
  font-weight: 500;
  font-size: 36px;
}

.sub-title {
  color: #c3c4c6;
  font-weight: 600;
  font-size: 18px;
  margin-top: 25px;
}

.para-txt {
  color: #7c6c6c;
  font-weight: 400;
  font-size: 18px;

}

.secondary-title {
  color: #202022;
  font-weight: 500;
  font-size: 36px;
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-right: 50px;
  padding-left: 80px;
  padding-top: 175px;
  padding-bottom: 100px;
}

/*button*/
.btn-main {
  background-color: #252425;
  border-radius: 5px;
  padding: 5px 20px;
  color: #F4F7FF;
  border: 1px solid #252425;
  transition: .4s ease-in-out;
}

.btn-main:hover {
  background: transparent;
  border: 1px solid #252425;
  color: #030303;
}

.btn-secondary {
  background-color: #070707;
  border-radius: 5px;
  padding: 15px 20px;
  color: #efeeef;
  font-weight: 500;
  border: 1px solid #252425;
  transition: .4s ease-in-out;
}

.btn-secondary:hover {
  background: #e6e4e6;
  border: 1px solid #252425;
  color: #030303;
}

/*home*/
.heroImage {
  width: 100%;
  height: 100vh;
  background: url("../images/heroImage.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  background-attachment: scroll;
  display: flex;
  align-items: center;
}

.heroImage h5 {
  color: white;
  text-shadow: 2px 2px #333;
}

.service-title h2 {
  color: #3c3b3c;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-title p {
  color: #3c3b3c;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-card {
  padding-top: 45px;
  background: transparent;
}

.service-image {
  height: 60px;
  width: 60px;
  display: block;
  margin: auto;
}

/*---------------------
  Car for Sell
-----------------------*/

.carSell {
  padding-bottom: 50px;
}

.carSell-title h2 {
  color: #3c3b3c;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carSell-title p {
  color: #3c3b3c;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carSell-card {
  padding-top: 45px;
  background: transparent;
}

.carSell-item {
  margin-bottom: 30px;
}

.carSell-item-pic-slider img {
  border-radius: 2px 2px 0 0;
  height: 16vw;
  width: 100%;
}

.owl-carousel .owl-dots {
  display: block !important;
}

.carSell-item-pic-slider .carSell-item .carSell-item-text-inner ul {
  padding-left: 0px;
  margin-top: 20px;
}


.carSell-item-pic-slider.owl-carousel .owl-dots button:last-child {
  margin-right: 0;
}

.carSell-item-text {
  border: 1px solid #ebebeb;
}

.carSell-item-text-inner {
  padding: 20px 0 14px 20px;
}

.carSell-item-text-inner .car-date {
  display: inline-block;
  font-size: 13px;
  color: #323232;
  font-weight: 700;
}

.carSell-item-text-inner h5 {
  margin-top: 10px;
  margin-bottom: 14px;
}

.carSell-item-text-inner h5 a {
  color: #353535;
  font-weight: 700;
}

.carSell-item-text-inner ul li {
  list-style: none;
  font-size: 15px;
  color: #8d8d8d;
  font-weight: 700;
  position: relative;
  display: inline-block;
  margin-right: 40px;
}

.carSell-item-text-inner ul li:after {
  position: absolute;
  right: -23px;
  top: 3px;
  height: 15px;
  width: 2px;
  background: #8d8d8d;
  content: "";
}

.carSell-item-text-inner ul li span {
  color: #323232;
}

.carSell-item-text-inner ul li:last-child {
  margin-right: 0;
}

.carSell-item-text-inner ul li:last-child:after {
  display: none;
}

.carSell-item-price {
  position: relative;
}

.carSell-item-price .car-option {
  font-size: 15px;
  color: #ffffff;
  font-weight: 700;
  background: #4971FF;
  display: inline-block;
  padding: 12px 22px 10px;
  border-radius: 2px 0 0 2px;
  position: absolute;
  left: 0;
  top: 0;
}

.carSell-item-price .car-option.sell {
  background: #db2d2e;
}

.carSell-item-price h6 {
  font-size: 18px;
  color: #030303;
  font-weight: 700;
  border-top: 1px solid #ebebeb;
  padding-left: 120px;
  padding-top: 14px;
  padding-bottom: 11px;
  display: flex;
  justify-content: space-around;
}

.carSell-item-price h6 span {
  color: #727171;
  font-size: 13px;
  font-weight: 400;
}

/* -------------
Car for sell End
---------------*/

/*----------------------
Service Categories Start
-----------------------*/
.serviceFeature-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.serviceFeature-title h2 {
  color: #3c3b3c;
  font-size: 28px;
  font-weight: 600;
}

.serviceFeature-title h6 {
  color: #3c3b3c;
  font-size: 18px;

}

.serviceFeature-card {
  padding-top: 45px;
  background: transparent;
  height: 100%;
  width: 100%;
}

.serviceFeature-image {
  height: 60px;
  width: 60px;
  display: block;
  margin: auto;
}

/*----------------------
Service Categories End
-----------------------*/

/**footer**/
.footer {
  background: linear-gradient(180deg, #2c2c30 0%, #050505 100%);
  padding: 50px 0px;
}

.footer-logo-text {
  color: #DDDDDD !important;
  position: relative;
  top: auto;
  left: auto;
  font-size: 30px;
  font-weight: 800;
}

.footer-text {
  color: #DDDDDD;
}

.footer-title {
  color: #FFF;
  font-weight: 700;
}

.social {
  color: #FFF;
  font-size: 24px;
}

.footer-copyright {
  color: #ffffff;
}


/***About us page start***/
.about-us-main {
  background-image: url("../images/about-banner.jpg");
  height: 60vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-us-main .main-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us-img {
  display: block;
  margin: auto;
  height: 55vh;
  width: 100%;
}

.mission-img {
  display: block;
  margin: auto;
  height: 45vh;
  width: 100%;
}

.vision-img {
  display: block;
  margin: auto;
  height: 50vh;
  width: 100%;
}

.testinomial-img {
  height: 25vh;
  width: 15vw !important;
  padding-bottom: 15px;
}

.about-us-main .main-content .content {
  width: 70%;
}

.who-we-are .main-content {
  padding: 80px 0px;
}

.main-box {
  width: 95%;
  color: #f0f0f0;
  background: linear-gradient(180deg, #545454 0%, #181818 100%);
  border-style: solid;
  border-width: 1px;
  border-color: rgb(57, 56, 56);
  border-radius: 0.3rem;
  background-color: #191919;
  overflow: hidden;
}

.main-box .content {
  padding: 20px 20px 20px 20px;
}

/***About us page end***/

/*----------------------
Sign-up form Start
-----------------------*/
.signup-container {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}

.sign-up-forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-container {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}

.sign-in-forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.appointment-container {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}

.appointment-up-forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

.sign-in-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.sign-in-form {
  z-index: 2;
}

.forms-title {
  font-size: 2.2rem;
  color: #444;
  margin-bottom: 10px;
}

.forms-input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 55px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.forms-input-field i {
  text-align: center;
  line-height: 55px;
  color: #222222;
  transition: 0.5s;
  font-size: 1.1rem;
}

.forms-input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #1e1e1e;
}

.forms-input-field input::placeholder {
  color: #2e2e2e;
  font-weight: 500;
}

.forgotPassword {
  text-decoration: none;
  color: #030303;
}

.forms-social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.forms-social-media {
  display: flex;
  justify-content: center;
}

.forms-social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}

.forms-social-icon:hover {
  color: #060606;
  border-color: #030303;
}

.forms-btn {
  width: 150px;
  background-color: #0f0f0e;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.forms-btn:hover {
  background-color: #3d3d3c;
}

.signup-panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.signup-container:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #585858 0%, #2c2c2c 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.signin-container:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #585858 0%, #2c2c2c 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.appointment-container:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #585858 0%, #2c2c2c 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.signup-image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.signup-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.signup-left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}


.signup-panel .signup-content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

.signup-panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.signup-panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.signup-btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

@media (max-width: 870px) {
  .signup-container {
    min-height: 800px;
    height: 100vh;
  }

  .signin-container {
    min-height: 585px;
  }

  .appointment-container {
    min-height: 950px;
    height: 100vh;
  }

  .signin-signup {
    width: 100%;
    top: 100%;
    transform: translate(-75%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signup-panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .signup-panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .signup-left-panel {
    grid-row: 1 / 2;
  }

  .signup-image {
    width: 200px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .signup-panel .signup-content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .signup-panel h3 {
    font-size: 1.2rem;
  }

  .signup-panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .signup-btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .signup-container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .signin-container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .appointment-container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .signup-container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .signin-container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .appointment-container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }
}

@media (max-width: 570px) {

  .sign-in-form {
    padding: 0px;
  }

  .signup-image {
    display: none;
  }

  .signup-panel .signup-content {
    padding: 0.5rem 1rem;
  }

  .signup-container {
    padding: 1.5rem;
  }

  .signin-container {
    padding: 1.5rem;
  }

  .appointment-container {
    padding: 1.5rem;
  }

  .signup-container:before {
    bottom: 72%;
    left: 50%;
  }

  .signin-container:before {
    bottom: 72%;
    left: 50%;
  }

  .appointment-container:before {
    bottom: 72%;
    left: 50%;
  }

  .signup-container.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }

  .signin-container.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }

  .appointment-container.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}

/*----------------------
Sign-up form End
-----------------------*/

/*----------------------
Repair car Start
-----------------------*/
.repair-car-main {
  background-image: url("../images/repair-banner.jpg");
  height: 60vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.repair-car-main .main-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.repair-car-main .main-content .content {
  width: 70%;
}

.repair-card {
  padding-bottom: 15px;
  transition: transform .2s ease-in-out;
  background: transparent;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(57, 56, 56);
  border-radius: 0.3rem;
  background-color: #191919;
  overflow: hidden;
}

.repair-card:hover {
  transform: scale(1.1);
}

.repair-img {
  height: 15vw;
  width: 100%;
  object-fit: cover;
  opacity: 0.6;
  display: block;
  margin: auto;
}

.repair-car-title {
  padding-top: 10px;
  font-size: 24px;
  font-weight: 500;
}

.repair-text {
  font-size: 14px;
}

.repair-body {
  padding-left: 15px;
  padding-right: 15px;
  color: #f0f0f0;
}

.repair-btn {
  color: #f0f0f0;
}

.repair-btn:hover {
  color: #f0f0f0;
  text-decoration: none;
}

/*----------------------
Repair car End
-----------------------*/

/*----------------------
Buy car Start
-----------------------*/

.buy-car-main {
  background-image: url("../images/Buy-car-bg.png");
  height: 60vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buy-car-main .main-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buy-car-main .main-content .content {
  width: 70%;
}

.buy-car-sub-info {
  color: #030303;
}

.buy-car-image {
  height: 100%;
  width: 100%;
  display: block;
  margin: auto;
}

.car-sell-text-list {
  padding-inline-start: 0px !important;
  padding-top: 15px;
}

.car-detail-page .main-content {
  padding: 80px 0px;
}


.car-detail-page .main-content .left-box .car-box {
  width: 800px;
  height: 527px;
  position: relative;
}


.car-detail-page .main-content .left-box .view-car-b {
  position: absolute;
  bottom: 15px;
  right: 45px;
}

.car-detail-page .main-content .left-box .view-car-b .view-gallary {
  background-color: #252425;
  color: #fff;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
}

.car-detail-page .main-content .left-box .view-car-b .view-gallary :hover {
  background-color: transparent;
}

.car-detail-page .main-content .left-box .car-box img {
  width: 100%;
  height: 100%;
}

.car-detail-page .main-content .right-box .car-info-div {
  background-color: #f7f7f7;
  padding: 20px 20px 0px 0px;
}

.car-detail-page .main-content .right-box .car-info-div .car-name {
  font-size: 36px;
  font-weight: 700;
}

.car-detail-page .main-content .right-box .car-info-div .car-date {
  font-size: 20px;
  font-weight: 500;
  color: #8d8d8d;
}

.car-detail-page .main-content .right-box .car-info-div .car-price {
  font-size: 32px;
  font-weight: 700;
}

.car-detail-page .main-content .right-box .car-info-div ul {
  padding-left: 0px;
  padding-top: 10px;
}

.car-detail-page .main-content .right-box .car-info-div ul li {
  list-style: none;
  font-size: 20px;
  color: #8d8d8d;
  font-weight: 700;
  position: relative;
  display: inline-block;
  margin-right: 40px;
}

.car-detail-page .main-content .right-box .car-info-div .for-sale {
  background-color: #db2d2e;
  padding: 12px 0px;
  width: 120px;
  color: #fff;
  text-align: center;
  margin-top: 16px;
}

.car-detail-page .main-content .right-box .car-info-div .vehicle-summary {
  padding-top: 20px;
  font-size: 28px;
  font-weight: 700;
}

.car-detail-page .main-content .right-box .car-info-div .vehicle-summary-list-items {
  list-style: none;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.car-features-div .main-content .detail-list {
  padding: 20px 0px 15px 0px;
  font-size: 24px;
  font-weight: 500;
}

.car-features-div .main-content .detail-list-name {
  padding-inline-start: 0px !important;
  padding: 0px 100px 40px 0px;
}

.car-features-div .main-content .detail-list-items {
  list-style: none;
  font-size: 18px;
  font-weight: 500;
  color: #5d5c5c;
  display: flex;
  justify-content: space-between;
}

.car-features-div .main-content .content .accordion .card {
  border: none;
}


.car-features-div .main-content .content .accordion .card-header {
  background-color: transparent;
  border-top: 1px solid rgba(0, 0, 0, .125);

}

.car-features-div .main-content .content .accordion .card-header button::before {
  font-family: FontAwesome;
  content: "\f068";
  color: #0f0f0e;
  margin-right: 20px;
  font-size: 18px;
}

.car-features-div .main-content .content .accordion .card-header button.collapsed::before {
  font-family: FontAwesome;
  content: "\f067";
  color: #0f0f0e;
  margin-right: 20px;
  font-size: 18px;
}

.car-features-div .main-content .content .accordion .card-header .accordion-btn {
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
  color: #0f0f0e;
}

.car-features-div .main-content .content .accordion .card-header .accordion-btn :focus {
  box-shadow: none;
}

.car-features-div .main-content .content .accordion .card-header .btn {
  box-shadow: none;
}

.car-features-div .main-content .content .accordion .card-header .accordion-btn.btn-link :hover {
  text-decoration: none;
}

.car-features-div .main-content .content .accordion .card .collapse li {
  font-size: 18px;
  font-weight: 500;
  color: #686161;
}

/*----------------------
Buy car End
-----------------------*/

/***Responsive***/
@media (min-width: 768px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 750px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 980px;
  }
}

@media (max-width: 992px) {}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1400px;
  }
}

@media (max-width: 1200px) {}

@media (min-width:992px) and (max-width:1200px) {}

@media (min-width:280px) and (max-width: 600px) {
  .unique-div {
    width: 100%;
  }

  .vector-img {
    position: absolute;
    top: -70px;
    left: 0px;
  }

  .vector-img2 {
    position: absolute;
    bottom: -80px;
    right: 0px;
  }

  .section-second {
    margin-bottom: 60px;
  }

  .main-title {
    font-size: 16px;
  }

  .sub-title {
    font-size: 14px;
  }

  .heroImage {
    padding: 80px 20px 80px 20px;
    height: 50vh;
  }

  .service-title p {
    text-align: center;
  }

  .carSell-item-pic-slider img {
    height: auto;
  }

  .carSell-title p {
    text-align: center;
  }

  .serviceFeature-title h6 {
    text-align: center;
  }

  .secondary-title {
    font-size: 34px;
  }

  .car-detail-page .main-content .left-box .car-box {
    width: auto;
    height: auto;
  }

  .testinomial-section .testinomial-img {
    width: 100% !important;
  }
}




@media (min-width: 768px) and (max-width: 992px) {}

@media (min-width:768px) and (max-width:2500px) {}